<template>
  <div class="index">
    <div class="header">
      <div class="title">{{detail.name}}</div>
      <div class="content" v-html="detail.content">
      </div>
    </div>
    <div class="icon">
      <img src="../../../assets/images/electronicCard/yuangong.png" alt="" style="width: 15px;height: 16px;"
        @click="skipIndex" />
      <img src="../../../assets/images/electronicCard/mobile.png" alt="" @click="onPhone"
        style="width: 15px;height: 15px;" v-if="lightPhone == false" />
      <img src="../../../assets/images/electronicCard/mobile2.png" alt="" style="width: 15px;height: 15px;"
        v-if="lightPhone" />
      <img src="../../../assets/images/electronicCard/weixin.png" alt="" @click="onWechat"
        style="width: 15px;height: 14px;" v-if="lightWechat == false" />
      <img src="../../../assets/images/electronicCard/weixin2.png" alt="" style="width: 15px;height: 14px;"
        v-if="lightWechat" />
    </div>
    <!-- 拨打电话弹窗 -->
    <van-dialog v-model="showPhoneCode" show-cancel-button cancelButtonText="否" confirmButtonText="是"
      confirmButtonColor="#3974C6">
      <div class="phoneT">是否拨打行员电话？</div>
    </van-dialog>
    <!-- 微信二维码弹窗 -->
    <van-popup v-model="showWechat" closeable style="width: 260px; height: 286px; text-align: center;">
      <img style="
          margin-top: 30px;
          width: 200px;
          height: 190px;
          text-align: center;
        " :src="employee.qrCode" alt="" />
      <p style="font-size: 16px; color: #515151">长按扫一扫<br />即可联系我</p>
    </van-popup>
  </div>
</template>
<script>
  import { productDetail } from '@/api/electronicCard'
  import { activityDetail, shareCallback, openUserInfoApi } from '@/api/electronicCard'
  import { wxConfig } from "@/utils/wxCodeAuth";
  export default {
    data() {
      return {
        lightPhone: false,
        lightWechat: false,
        showPhoneCode: false,
        showWechat: false,
        detail: '',
        employee: '',
        phoneNum: '',
        employeeId: '',
        unionid: '',
        id: '',
      };
    },
    created() {
      this.employeeId = this.$route.query.employeeId;
      this.id = this.$route.query.id;
      this.getOpenUserInfo();
      // this.getData()
    },
    methods: {
      getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
          employeeId: this.employeeId,
        }).then((res) => {
          if (res.data.openid === undefined) {
            let redirectUrl =
              location.protocol +
              "//" +
              location.host +
              "/auth/vcard?employeeId=" +
              that.employeeId +
              "&target=" +
              encodeURIComponent(
                "/electronicCard/financialinfo/detail" + location.search
              );
            window.location.href = redirectUrl;
            return;
          }
          this.unionid = res.data.unionid;
          this.getData();
        });
      },
      skipIndex() {
        this.$router.push({ path: '/vcard/record', query: { id: this.employeeId } })
      },
      getData() {
        const id = this.$route.query.id
        this.employeeId = this.$route.query.employeeId
        productDetail({ id, employeeId: this.employeeId,unionId:this.unionid}).then(res => {
          this.detail = res.data.detail
          this.employee = res.data.employee
          this.phoneNum = res.data.employee.mobile
          let that = this;
          //分享内容
          this.shareOption = {
            title: this.detail.name, // 分享标题
            desc: '', // 分享描述
            link: this.detail.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            success: function (res) {
              // 用户点击了分享后执行的回调函数
              let params = {
                employeeId: that.employeeId,
                unionId: that.unionid,
                type: '7',
                targetId: that.id
              }
              shareCallback(params)
            },
          };
          let shareFunction = function () {
            //需在用户可能点击分享按钮前就先调用
            wx.onMenuShareTimeline(that.shareOption);//分享到朋友圈
            wx.onMenuShareAppMessage(that.shareOption);//分享到朋友
            wx.onMenuShareQQ(that.shareOption);//分享到qq
            wx.onMenuShareQZone(that.shareOption);//分享到QQ空间
          };
          //加载微信配置
          wxConfig(shareFunction);
        })
      },
      onPhone() {
        this.lightWechat = false;
        this.lightPhone = true;
        // this.showPhoneCode = true
        window.location.href = `tel:${this.phoneNum}`;
      },
      onWechat() {
        this.lightWechat = true;
        this.lightPhone = false;
        this.showWechat = true
      },
      showPhone() {
        this.showPhoneCode = true;
      },
    },
  };
</script>
<style lang="less" scoped>
  .header {
    width: 94%;
    margin: 0 auto;
    overflow: auto;

    .title {
      text-align: center;
      width: 100%;
      height: 35px;
      font-size: 16px;
      color: #000000;
      line-height: 24px;
      margin-top: 20px;
    }

    .content {
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
    }
  }

  .icon {
    position: fixed;
    bottom: 20px;
    right: 18px;
    width: 33px;
    height: 86px;
    background: #f7f7f7;
    border-radius: 17px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    img {
      width: 14px;
      height: 15px;
    }
  }

  .phoneT {
    text-align: center;
    margin: 38px;
    height: 35px;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
  }

  /deep/ img {
    max-width: 100% !important;
  }
</style>