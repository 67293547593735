<template>
  <div class="all">
    <div class="top">
      <van-search
        v-model="searchVal"
        placeholder="请输入搜索关键词"
        shape="round"
        @search="onSearch"
      />
      <van-tabs
        v-model="activeName"
        color="#3974C6"
        title-active-color="#3974C6"
        @click="change"
      >
        <van-tab
          v-for="(item, index) in categoryList"
          :key="index"
          :title="item.name"
          :name="item.id"
        >
          <div class="main">
            <div
              class="content"
              v-for="(res, i) in List"
              :key="i"
              @click="skipDetail(res.id,res.fullCover)"
            >
              <div class="left">
                <div class="title">
                  {{ res.name }}
                </div>
                <div class="tag">
                  <span v-for="(tag, ind) in res.tags" :key="ind">{{
                    tag
                  }}</span>
                </div>
                <div class="shareData">
                  {{ res.shareNum }}人已分享，{{ res.viewNum }}人已阅读
                </div>
              </div>
              <div class="right">
                <img :src="res.fullCover" alt="" />
              </div>
            </div></div
        ></van-tab>
      </van-tabs>
    </div>
  </div>
</template>
    <script>
import { category } from "@/api/specialTopic";
import { topicList } from "../../api/specialTopic";

export default {
  data() {
    return {
      searchVal: "",
      activeName: 1,
      categoryList: [],
      List: [],
      employeeId:''
    };
  },
  created() {
    this.employeeId = this.$route.query.id
    this.getCategoryList();
  },
  methods: {
    getCategoryList() {
      category({employeeId:this.employeeId}).then((res) => {
        this.categoryList = res.data;
        this.activeName = res.data[0].id?res.data[0].id:''
        this.getTopicList();
      });
    },
    getTopicList() {

      topicList({ categoryId: this.activeName, name: this.searchVal,employeeId:this.employeeId }).then(
        (res) => {
          this.List = res.data.list;
        }
      );
    },
    onSearch() {
      this.getTopicList();
    },
    change() {
      console.log("this.active", this.activeName);
      this.getTopicList();
    },
    skipDetail(id,cover) {
      this.$router.push({
        path: "/electronicCard/specialTopicDetail",
        query: { id: id,employeeId:this.employeeId,cover },
      });
    },
  },
};
</script>
    <style lang="less" scoped>
.all {
  height: 100%;
  overflow: auto;
  background-color: #f2f3f7;
}
.main {
  margin-top: 13px;

  .content {
    width: 94%;
    margin: 0 auto;
    min-height: 111px;
    background: #ffffff;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .left {
    margin-top: 10px;
    margin-left: 10px;
    width: 68%;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tag {
      min-height: 14px;
      span {
        display: inline-block;
        padding: 4px;
        height: 18px;
        line-height: 10px;
        background: #eef1f9;
        border-radius: 2px;
        font-size: 10px;
        color: #7cadfe;
        margin-right: 4px;
      }
    }
    .shareData {
      font-size: 11px;
      color: #a5a5a5;
    }
  }
  .right {
    width: 23%;
    margin-right: 10px;
    margin-top: 10px;
    img {
      width: 98%;
    }
  }
}
</style>