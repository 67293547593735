<template>
  <div class="index">
    <div class="userInfo">
      <div class="content">
        <div class="contentLeft">
          <img :src="employee.fullImage" alt="" />
          <div style="margin-left: 8px">
            <div class="item marquee" style="width: 144px">
              <div class="marquee-wrap">
                <div class="marquee-content name">
                  {{ employee.name }}
                </div>
              </div>
            </div>
            <div class="position">
              {{ employee.departmentName }}｜{{ employee.position }}
            </div>
          </div>
        </div>
        <div class="contentRight">
          <div class="phone" style="margin-right: 20px">
            <img
              class="pic"
              src="../../../assets/images/electronicCard/phone.png"
              alt=""
              @click="daPhone"
            />
          </div>
          <div class="phone" style="margin-right: 20px">
            <img
              class="pic"
              src="../../../assets/images/electronicCard/wechat.png"
              alt=""
              @click="showWechat = true"
            />
          </div>
          <!-- <div class="phone">
              <img
                class="pic"
                src="../../../assets/images/electronicCard/wechat.png"
                alt=""
                @click="showWechat = true"
              />
            </div> -->
        </div>
      </div>
    </div>
    <div class="banxin">
      <h5>{{ detail.title }}</h5>
      <div class="top">
        <div class="left">
          来自：{{ detail.author_name }} &nbsp;&nbsp;阅读 {{ detail.view_num }}
        </div>
        <div class="right">
          {{ detail.date }}
        </div>
      </div>
      <div
        class="comment"
        v-if="detail.comment.comment != ''"
        @click.stop="showDetail = true"
      >
        点开点评
      </div>
      <div class="myComment" v-if="showDetail == true">
        {{ detail.comment.comment }}
        <div @click.stop="showDetail = false" class="shouqi">收起</div>
      </div>
      <div class="rich" v-html="detail.content"></div>
    </div>
    <div class="recommend" v-if="detail.comment.recommended_product.length > 0">
      <div class="top">推荐产品</div>
      <div
        class="content"
        v-for="(res, i) in detail.comment.recommended_product"
        :key="i"
        @click="skipDetail(res.id)"
      >
        <div class="left">
          <div class="title">{{ res.name }}</div>
          <div class="abstruct">
            {{ res.summary }}
          </div>
        </div>
        <div class="right">
          <img :src="res.fullImage" alt="" />
        </div>
      </div>
    </div>
    <!-- 版权声明 -->
    <div class="copyright">
      <div class="box_title">免责声明：</div>
      <div class="box_cen">
        以上内容仅供参考，引用之目的在于共享资讯，不代表对#{{
          detail.author_name
        }}#内容的真实性、准确性负责。以上内容不构成对任何人的投资建议，敬请您独立决策并承担风险。如信息内容侵犯知识产权，请提交相关链接至邮箱：service@qsjykj.com.cn，我们将及时处理。市场有风险，投资须谨慎
      </div>
    </div>
    <div style="height: 45px"></div>
    <div class="last">
      <div class="cont">
        <div class="right" @click="share">分享</div>
      </div>
    </div>
    <!-- 微信二维码弹窗 -->
    <van-popup
      v-model="showWechat"
      closeable
      style="width: 260px; height: 286px; text-align: center"
    >
      <img
        style="
          margin-top: 30px;
          width: 200px;
          height: 190px;
          text-align: center;
        "
        :src="employee.qrCode"
        alt=""
      />
      <p style="font-size: 16px; color: #515151">长按扫一扫<br />即可联系我</p>
    </van-popup>
    <!-- 分享提示弹窗 -->
    <van-popup class="sharePopup" v-model="showShare">
      <img
        class="img1"
        src="../../../assets/images/electronicCard/wechatshare.png"
        alt=""
      />
      <img
        class="img2"
        @click="showShare = false"
        src="../../../assets/images/electronicCard/kownBtn.png"
        alt=""
      />
    </van-popup>
  </div>
</template>
<script>
import {
  newsDetail,
  shareCallback,
  openUserInfoApi,
  autoTagCallback,
} from "@/api/electronicCard";
import { wxConfig } from "@/utils/wxCodeAuth";

export default {
  data() {
    return {
      detail: "",
      employee: "",
      phoneNum: "",
      showWechat: false,
      showDetail: false,
      id: "",
      employeeId: "",
      url: "",
      shareOption: "",
      showShare: false,
      unionid: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.employeeId = this.$route.query.employeeId;
    this.getOpenUserInfo();
  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        employeeId: that.employeeId,
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl =
            location.protocol +
            "//" +
            location.host +
            "/auth/vcard?employeeId=" +
            that.employeeId +
            "&target=" +
            encodeURIComponent(
              "/electronicCard/caijing/detail" + location.search
            );
          window.location.href = redirectUrl;
          return;
        }
        this.unionid = res.data.unionid;

        this.getData();
      });
    },
    getSignUrl() {
      var signLink = "";
      var ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        signLink = localStorage.getItem("WxCodeUrl");
        if (!signLink) signLink = location.href;
      } else {
        signLink = location.href;
      }
      return signLink;
    },
    share() {
      this.showShare = true;
    },
    daPhone() {
      window.location.href = `tel:${this.phoneNum}`;
    },
    getData() {
      newsDetail({
        id: this.id,
        employeeId: this.employeeId,
        unionId: this.unionid,
      }).then((res) => {
        //如果content_type等于2，就直接跳url
        if (res.data.content_type == 2) {
          window.location.href = res.data.url;
          return;
        }
        this.detail = res.data.detail;
        this.employee = res.data.employee;
        this.phoneNum = res.data.employee.mobile;
        this.url = window.location.href;
        // this.url = encodeURIComponent(this.getSignUrl());
        console.log("this.url", this.url);
        this.detail.content1 = this.detail.content.replace(/<[^>]+>/g, "");
        // 自动标签回调
        if (res.data.detail.autoTag == 1) {
          let timer = setTimeout(() => {
            console.log('res.data.detail.countdown',res.data.detail.countdown)
              autoTagCallback({
                targetId: this.id,
                employeeId: this.employeeId,
                unionId: this.unionid,
                type: 1,
              }).then((res) => {
                console.log("sucess");
                clearTimeout(timer)
              });
          }, res.data.detail.countdown*1000);
        }
        let that = this;
        //分享内容
        this.shareOption = {
          title: "[资讯]" + this.detail.title.slice(0, 26), // 分享标题
          desc: this.detail.content1, // 分享描述
          link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: this.employee.fullImage, // 分享图标
          success: function (res) {
            // 用户点击了分享后执行的回调函数
            let params = {
              employeeId: that.employeeId,
              unionId: that.unionid,
              type: "4",
              targetId: that.id,
            };
            shareCallback(params);
          },
        };

        let shareFunction = function () {
          //需在用户可能点击分享按钮前就先调用
          wx.onMenuShareTimeline(that.shareOption); //分享到朋友圈
          wx.onMenuShareAppMessage(that.shareOption); //分享到朋友
          wx.onMenuShareQQ(that.shareOption); //分享到qq
          wx.onMenuShareQZone(that.shareOption); //分享到QQ空间
        };
        //加载微信配置
        wxConfig(shareFunction);
      });
    },
    skipDetail(id) {
      this.$router.push({
        path: "/electronicCard/financialinfo/detail",
        query: {
          id,
          employeeId: this.employeeId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.sharePopup {
  background-color: transparent !important;
  top: 20%;
  width: 100%;
  text-align: center;
}
.index {
  height: 100%;
  background: #fff;
  overflow: auto;
}

.userInfo {
  width: 100%;
  height: 79px;
  background: url("../../../assets/images/electronicCard/pinkBg.png");
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.05);
}

.content {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .contentLeft {
    display: flex;
    margin-left: 14px;
    margin-top: 10px;

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    .name {
      margin-top: 6px;
      height: 21px;
      font-size: 18px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 21px;
    }

    .position {
      margin-top: 10px;
      height: 15px;
      font-size: 12px;
      color: #9b9b9b;
      line-height: 15px;
    }
  }

  .contentRight {
    display: flex;
    justify-content: space-around;
    // .phone {
    //   width: 31px;
    //   height: 31px;
    //   background-color: #fff;
    //   border-radius: 50%;
    //   text-align: center;
    //   line-height: 22px;
    // }

    .pic {
      width: 14px;
      height: 14px;
    }
  }
}

.banxin {
  width: 94%;
  margin: 0 auto;
}

h5 {
  margin: 16px 10px 10px 10px;
  min-height: 32px;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
  line-height: 24px;
  text-align: center;
}

.top {
  display: flex;
  justify-content: space-between;
  height: 19px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #717174;
  line-height: 19px;
  padding: 0 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left {
  width: 56%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ img {
  max-width: 100% !important;
}

.last {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 42px;

  .cont {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      width: 100%;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #3974c6;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}

.comment {
  margin-top: 10px;
  width: 100%;
  height: 29px;
  background: #f2f3f7;
  font-size: 13px;
  color: #3975c6;
  line-height: 29px;
  text-indent: 6px;
}
.rich {
  margin-top: 8px;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  text-align: justify;
  text-justify: distribute-all-lines;
  /* text-align-last: justify; */
}
.myComment {
  text-indent: 6px;
  padding: 10px 0;
  background: #f2f3f7;
  font-size: 13px;
  color: #4a4a4a;
  line-height: 20px;
}

.shouqi {
  display: inline-block;
  width: 44px;
  height: 18px;
  font-size: 13px;
  color: #3975c6;
  line-height: 19px;
}

.recommend {
  background: #f2f3f7;
  margin-top: 14px;
  overflow: hidden;

  .top {
    margin: 14px 0;
    width: 94%;
    margin-left: 3%;
    // margin: 0 auto;
    width: 105px;
    height: 21px;
    font-size: 15px;
    text-indent: 8px;
    font-weight: bold;
    color: #262626;
    border-left: 2px solid #3974c6;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
    height: 107px;
    background: #ffffff;
    margin-bottom: 10px;
  }

  .left {
    width: 60%;

    .title {
      margin: 0px 0 10px 5px;
      height: 21px;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
    }

    .abstruct {
      font-size: 13px;
      color: #959595;
      margin-left: 5px;
      line-height: 22px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2; //显示几行
    }
  }

  .right {
    width: 30%;
    margin-right: 5px;

    img {
      width: 107px;
      height: 81px;
    }
  }
}

.img1 {
  width: 314px;
  height: 141px;
}

.img2 {
  width: 158px;
  height: 65px;
  margin-top: 30px;
}
.copyright {
  width: 94%;
  padding: 10px;
  background: #f8f9fb;
  margin: 10px;
  margin: 0 auto;
  border-radius: 6px;
  margin-top: 12px;
  /* margin-bottom: 70px; */

  .box_title {
    color: #acadb1;
    font-size: 12px;
  }

  .box_cen {
    margin-top: 5px;
    color: #acadb1;
    font-size: 12px;
    line-height: 20px;
  }
}
.marquee {
  overflow: hidden;
}

.marquee .marquee-wrap {
  width: 100%;
  animation: marquee-wrap 10s infinite linear;
}

.marquee .marquee-content {
  float: left;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee-content 10s infinite linear;
}

@keyframes marquee-wrap {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-content {
  0%,
  30% {
    transform: translateX(0);
  }
  70%,
  100% {
    transform: translateX(-100%);
  }
}
</style>