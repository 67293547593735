import {
    request,request_op
} from "@/plugins/axios";


// 获取早报首页
export function morningPaperIndex(params) {
    return request({
        url: '/morningPost/homepage',
        method: 'get',
        params
    })
}

// 晚报首页
export function eveningPaperIndex(params) {
    return request({
        url: '/nightPost/homepage',
        method: 'get',
        params
    })
}
// 获取早报点评
export function morningPaperComment(params) {
    return request({
        url: '/workVcardPostComments/getMorningComment',
        method: 'get',
        params
    })
}
//朋友圈详情
export function circleDetail(params) {
    return request({
        url: '/friendsCircleInformation/detail',
        method: 'get',
        params
    })
}

// 获取晚报点评
export function eveningPaperComment(params) {
    return request({
        url: '/workVcardPostComments/getNightComment',
        method: 'get',
        params
    })
}
//晚报投资话题选择
export function chooseOption(params) {
    return request({
        url: '/nightPost/choice',
        method: 'post',
        data:params
    })
}
//获取用户信息基本信息
export function openUserInfoApi(params) {
    return request_op({
        url: '/openUserInfo/vcard',
        method: 'get',
        params
    })
}