<template>
  <div class="index">
    <div class="header">
      <div class="title">{{detail.name}}</div>
      <div class="content" v-html="detail.content">

      </div>
    </div>
  </div>
</template>
<script>
  import { activityDetail, shareCallback, openUserInfoApi,autoTagCallback } from '@/api/electronicCard'
  import { wxConfig } from "@/utils/wxCodeAuth";
  export default {
    data() {
      return {
        detail: {},
        employeeId: '',
        id: '',
        unionid: ''
      };
    },
    created() {
      this.employeeId = this.$route.query.employeeId;
      this.id = this.$route.query.id;
      this.getOpenUserInfo();
    },
    methods: {
      getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
          employeeId: this.employeeId,
        }).then((res) => {
          if (res.data.openid === undefined) {
            let redirectUrl =
              location.protocol +
              "//" +
              location.host +
              "/auth/vcard?employeeId=" +
              that.employeeId +
              "&target=" +
              encodeURIComponent(
                "/electronicCard/activity/detail" + location.search
              );
            window.location.href = redirectUrl;
            return;
          }
          this.unionid = res.data.unionid;
          this.getData();
        });
      },
      getData() {
        const id = this.$route.query.id
        const employeeId = this.$route.query.employeeId
        const unionId= this.unionid
        activityDetail({ id, employeeId,unionId }).then(res => {
          //如果type等于2或者3，就直接跳url
          if(res.data.type==2||res.data.type==3){
            window.location.href = res.data.url
            return
          }
          this.detail = res.data
          // 自动标签回调
        if (res.data.autoTag == 1) {
          let timer = setTimeout(() => {
            console.log('res.data.detail.countdown',res.data.countdown)
              autoTagCallback({
                targetId: this.id,
                employeeId: this.employeeId,
                unionId: this.unionid,
                type: 3,
              }).then((res) => {
                console.log("sucess");
                clearTimeout(timer)
              });
          }, res.data.countdown*1000);
        }
          let that = this;
          //分享内容
          this.shareOption = {
            title: this.detail.name, // 分享标题
            desc: '', // 分享描述
            link: this.detail.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            success: function (res) {
              // 用户点击了分享后执行的回调函数
              let params = {
                employeeId: that.employeeId,
                unionId: that.unionid,
                type: '6',
                targetId: that.id
              }
              shareCallback(params)
            },
          };
          let shareFunction = function () {
            //需在用户可能点击分享按钮前就先调用
            wx.onMenuShareTimeline(that.shareOption);//分享到朋友圈
            wx.onMenuShareAppMessage(that.shareOption);//分享到朋友
            wx.onMenuShareQQ(that.shareOption);//分享到qq
            wx.onMenuShareQZone(that.shareOption);//分享到QQ空间
          };
          //加载微信配置
          wxConfig(shareFunction);
        })
      },
    }
  };
</script>
<style lang="less" scoped>
  .header {
    width: 94%;
    margin: 0 auto;
    overflow: auto;

    .title {
      text-align: center;
      width: 100%;
      height: 35px;
      font-size: 16px;
      color: #000000;
      line-height: 24px;
      margin-top: 20px;
    }

    .content {
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
    }
  }

  /deep/ img {
    max-width: 100% !important;
  }
</style>