<template>
  <div class="index">
    <div class="top">
      <div class="section">
        <van-dropdown-menu >
          <van-dropdown-item v-model="value" :options="options" @change="selectClassify"/>
        </van-dropdown-menu>
      </div>
      <div :class="[isRec == 1 ? 'act' : 'topRight']" @click="rec">
        推荐优先
      </div>
      <div :class="[isActive == 1 ? 'act' : 'topRight']" @click="view">
        浏览优先
      </div>
    </div>
    <div class="content" v-for="(item,index) in productData" :key="index">
      <div class="banxin">
        <div class="left"><img :src="item.fullImage" alt="" /></div>
        <div class="right" @click="skipDetail(item.id)">
          <div class="rightTop">
            {{item.name}} &nbsp;<img
              src="../../assets/images/electronicCard/dianzan.png"
              alt=""
              v-if="item.promotionMark==1"
            />
          </div>
          <div class="rightCenter">
            {{ item.summary }}
          </div>
          <div class="views">浏览次数:{{item.viewNum}}</div>
        </div>
      </div>
      <div class="detail"><div class="m16">查看详情</div></div>
    </div>
  </div>
</template>
<script>
import { jingRongClassify,productList } from "@/api/electronicCard"

export default {
  data() {
    return {
      isActive: "",
      isRec:'',
      value: 0,
      options: [],
      productData:[],
      classifyId:'',
      recommend:'',
      lastest:'',
      employeeId:''

    };
  },
  created(){
    this.getClassify()
    this.getData()
  },
  methods: {
    getClassify(){
      this.employeeId = this.$route.query.id
      jingRongClassify({employeeId:this.employeeId}).then(res=>{
        console.log('fenlei',res.data)
        res.data.unshift({ name: "全部产品", id: 0})
        this.options=res.data.map(res=>{
          return {
            text: res.name,
            value: res.id
          }
        })
        console.log('this option',this.options)
      })
    },
    skipDetail(id){
      this.$router.push({
        path: "/electronicCard/financialinfo/detail",
        query: {
          id,
          employeeId:this.employeeId
        },
    })
  },
  selectClassify() {
      this.getData();
    },
    getData(){
      // this.classifyId = this.$route.query.categoryId
      // this.value = this.$route.query.categoryId
      // console.log('this.calue',this.value)
      this.employeeId = this.$route.query.id
      productList({ categoryId: this.value, promotionMark:this.recommend, publish:this.lastest,employeeId:this.employeeId}).then(res=>{
        console.log('产品列表',res)
        this.productData = res.data.list
      })
    },
    view() {
      if (this.isActive == 1) {      
        this.isActive = "";
        this.lastest = ''
        this.getData()
      } else {
        this.isActive = 1;
        this.lastest = 1
        this.getData()
      }
    },
    rec() {
      if (this.isRec == 1) {
        this.isRec = "";
        this.recommend = ''
        this.getData()
      } else {
        this.isRec = 1;
        this.recommend = 1
        this.getData()
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-dropdown-item {
  top: 50px !important;
}
/deep/ .van-dropdown-menu__bar {
  box-shadow: none;
}
/deep/ .van-dropdown-menu__title{
  font-size: 13px;
}
.index {
  overflow: auto;
}
.top {
  display: flex;
  justify-content: space-around;
  height: 50px;
  border-bottom: 1px solid #979797;
}
.section {
  height: 46px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  line-height: 46px;
}
span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.act {
  margin-top: 15px;
  float: left;
  height: 18px;
  font-size: 13px;
  line-height: 19px;
  // font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  // background-color: #ccc;
  color: #3974c6;
}
.topRight {
  margin-top: 15px;
  float: left;
  height: 18px;
  font-size: 13px;
  // font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #3c3c3c;
  line-height: 19px;
}
.content {
  position: relative;
  width: 90%;
  height: 110px;
  background: #ffffff;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 12px;
  overflow: hidden;
  .banxin {
    width: 90%;
    height: 80px;
    margin: 0 auto;
    margin-top: 15px;

    .left {
      float: left;
      margin-right: 11px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      float: left;
      width: 63%;
      
      .rightTop {
        height: 18px;
        font-size: 16px;
        color: #000000;
        img {
          width: 13px;
          height: 13px;
        }
      }
      .rightCenter {
        margin-top:10px;
        height: 34px;
        font-size: 13px;
        color: #7f7f7f;
        line-height: 18px;
        overflow: hidden;//多出的隐藏
        text-overflow: ellipsis;//多出部分用...代替
        display: -webkit-box;//定义为盒子模型显示
        -webkit-line-clamp: 2;//用来限制在一个块元素显示的文本的行数
        -webkit-box-orient: vertical;//从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
      }
      .views {
        margin-top: 6px;
        height: 15px;
        font-size: 11px;
        color: #bbbbbb;
        line-height: 15px;
      }
    }
  }
  .detail {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 110px;
    background: #3974c6;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 0px 10px 10px 0px;
    font-size: 15px;
    color: #ffffff;
    line-height: 19px;
    text-align: center;
    .m16 {
      margin-top: 14px;
    }
  }
}
</style>
  