import {request,request_op} from "../plugins/axios";

//首页数据
export function getBankindex(params) {
    return request({
        url: '/bank/index',
        method: 'get',
        params: params
    })
}
//支行详情
export function getbankHome(params) {
    return request({
        url: '/bank/bankHome',
        method: 'get',
        params: params
    })
}
//获取用户信息
export function getUserInfo (params) {
    return request_op({
        url: '/openUserInfo/bank',
        method: 'get',
        params: params
    })
}
//获取授权信息
export function getEmpowerInfo (params) {
    return request({
        url: '/auth/bank',
        method: 'get',
        params: params
    })
}
//支行搜索
export function getSearchList (params) {
    return request({
        url: '/bank/search',
        method: 'get',
        params: params
    })
}
//用户协议
export function getUserAgree (params) {
    return request({
        url: '/bankAgreeon/index',
        method: 'get',
        params: params
    })
}
//首页-预约
export function setAppment (params) {
    return request({
        url: '/bankServiceAppointment/appointment',
        method: 'post',
        data: params
    })
}
//预约-业务列表
export function bankServiceRecord (params) {
    return request({
        url: '/bankServiceAppointment/bankServiceRecord',
        method: 'get',
        params: params
    })
}
//预约-支行列表
export function bankRecord (params) {
    return request({
        url: '/bankServiceAppointment/bankRecord',
        method: 'get',
        params: params
    })
}
//预约-客户经理
export function bankManagerRecord (params) {
    return request({
        url: '/bankServiceAppointment/bankManagerRecord',
        method: 'get',
        params: params
    })
}
//我的-预约
export function record (params) {
    return request({
        url: '/bankServiceAppointment/record',
        method: 'get',
        params: params
    })
}
//我的-预约-详情
export function recordDetail (params) {
    return request({
        url: '/bankServiceAppointment/detail',
        method: 'get',
        params: params
    })
}
//电子名片获取微官网URL
export function getbankHomeUrl (params) {
    return request({
        url: '/bank/getbankHomeUrl',
        method: 'get',
        params: params
    })
}
//获取转发内容
export function getbankShare (params) {
    return request({
        url: '/bankShare/show',
        method: 'get',
        params: params
    })
}