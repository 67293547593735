<template>
  <div class="all">
    <div style="position: relative; height: 218px; overflow: auto">
      <div class="top">
        <img v-if="detailData.fullPoster!=''" :src="detailData.fullPoster" alt="">
        <img
        v-else
          src="../../../assets/images/electronicCard/specialTopic.png"
          alt=""
        />
      </div>
      <div class="detail">
        <div class="title">{{ detailData.name }}</div>
        <div class="abstruct">{{ detailData.summary }}</div>
      </div>
    </div>
    <div
      class="list"
      v-for="(item, index) in detailData.informationList"
      :key="index"
    >
      <div
        class="common"
        v-if="item.content_type == 1"
        @click="skipNewsDetail(item.id)"
      >
        <div class="left">
          <div class="leftTop">{{ item.title }}</div>
          <div class="leftBot">
            <span class="type">资讯类型:内容</span><span class="num"></span>
          </div>
        </div>
        <div class="right">
          <img :src="item.fullCover" alt="" />
        </div>
      </div>
      <div
        class="sec"
        v-else-if="item.content_type == 2"
        @click="skipRadarDetail(item.url)"
      >
        <div class="secTop">{{ item.title }}</div>
        <div class="right" style="margin-left: 10px">
          <span class="type">资讯类型:雷达</span><span class="num"></span>
        </div>
      </div>
      <div class="poster" v-else>
        <div class="title">{{ item.title }}</div>
        <!-- <div>
          <img :src="item.fullPoster" alt="" />
        </div> -->
        <div class="rightposter">
          <img :src="item.fullPoster" @click.stop="createPoster(item.fullPoster)" alt="" />
          <div class="userInfo">
            <div class="left">
              <img class="avatar" :src="userInfo.fullImage" alt="">
              <div class="info">
                <div class="name">{{ userInfo.name }}</div>
                <div class="m12">{{ userInfo.departmentName }}</div>
                <!-- <div class="m12">{{ userInfo.position }}</div> -->
                <!-- <div class="m12">{{ userInfo.mobile }}</div> -->
              </div>
            </div>
            <div class="right">
              <img class="qrcode" :src="userInfo.qrCode" alt="">
            </div>
          </div>
        </div>
        <div class="right" style="margin-left: 10px">
          <span class="type">资讯类型:海报</span><span class="num"></span>
        </div>
      </div>
    </div>
    <div
      class="list"
      v-for="(item, index) in detailData.radarList"
      :key="index"
    >
      <div class="sec" @click="skipRadarDetail(item.url)">
        <div class="secTop">{{ item.title }}</div>
        
        <div class="right" style="margin-left: 10px">
          <span class="type">资讯类型:雷达</span><span class="num"></span>
        </div>
      </div>
    </div>
    <div style="height: 64px"></div>
    <div class="last">
      <div class="lastContent">
        <div>
          <img
            v-if="likesType == 0"
            src="../../../assets/images/electronicCard/dianzan1.png"
            alt=""
            @click="dianZan"
          />
          <img
            v-if="likesType == 1"
            src="../../../assets/images/electronicCard/dianzan3.png"
            alt=""
          />
          <span> {{ detailData.likesNum }}</span>
        </div>
        <div>
          <img
            src="../../../assets/images/electronicCard/dianzan2.png"
            alt=""
            v-if="dislikesType == 0"
            @click="dianCai"
          />
          <img
            src="../../../assets/images/electronicCard/dianzan4.png"
            alt=""
            v-if="dislikesType == 2"
          />
          <span> {{ detailData.disLikesNum }}</span>
        </div>
        <div class="btn" @click="share">分享</div>
      </div>
    </div>
    <!-- 分享提示弹窗 -->
    <van-popup v-model="showShare">
      <img
        class="img1"
        src="../../../assets/images/electronicCard/wechatshare.png"
        alt=""
      />
      <img
        class="img2"
        @click="showShare = false"
        src="../../../assets/images/electronicCard/kownBtn.png"
        alt=""
      />
    </van-popup>
  </div>
</template>
    <script>
import { topicDetail, likeTap } from "@/api/specialTopic";
import { openUserInfoApi } from "@/api/morningPaper";
import { wxConfig } from "@/utils/wxCodeAuth";
import { vcardIndex,getbase64,shareCallback,newVcardIndex,autoTagCallback } from "@/api/electronicCard";
export default {
  data() {
    return {
      detailData: "",
      id: "",
      employeeId: "",
      unionid: "",
      likesType: 0,
      dislikesType: 0,
      showShare: false,
      employee: "",
      userInfo:'',
      qrcode:''
    };
  },
  created() {
    this.employeeId = this.$route.query.employeeId;
    this.id = this.$route.query.id;
    this.getUserInfo()
    this.getOpenUserInfo();
    this.get64()

  },
  methods: {
    get64() {
      getbase64({employeeId:this.employeeId}).then((res) => {
        this.qrcode = res.data.base64QrCode;
      });
    },
    createPoster(imgurl){
      if(this.avatar!=''){
        this.$router.push({ path: "/poster/create", query: { imgurl,qrcode:this.qrcode,employeeId:this.employeeId } });
      }
    },
    getUserInfo() {
      newVcardIndex({ employeeId: this.employeeId }).then((res) => {
        this.userInfo = res.data.employee
      })
    },
    share() {
      this.showShare = true;
    },
    dianZan() {
      if (this.dislikesType != 2) {
        this.likesType = 1;
        likeTap({
          employeeId: this.employeeId,
          type: 1,
          id: this.id,
          unionid: this.unionid,
        }).then((res) => {
        });
      }
    },
    dianCai() {
      if (this.likesType != 1) {
        this.dislikesType = 2;
        likeTap({
          employeeId: this.employeeId,
          type: 2,
          id: this.id,
          unionid: this.unionid,
        }).then((res) => {
        });
      }
    },
    skipRadarDetail(url) {
      location.href = url;
    },
    getDetail() {
      topicDetail({
        id: this.id,
        employeeId: this.employeeId,
        unionid: this.unionid,
      }).then((res) => {
        this.detailData = res.data.detail;
        this.url = window.location.href
        // 自动标签回调
        if (res.data.detail.autoTag == 1) {
          let timer = setTimeout(() => {
            console.log('res.data.detail.countdown',res.data.detail.countdown)
              autoTagCallback({
                targetId: this.id,
                employeeId: this.employeeId,
                unionId: this.unionid,
                type: 2,
              }).then((res) => {
                console.log("sucess");
                clearTimeout(timer)
              });
          }, res.data.detail.countdown*1000);
        }
        // this.url = encodeURIComponent(this.getSignUrl());
        let that = this;
        //分享内容
        this.shareOption = {
          title: this.detailData.name, // 分享标题
          desc: this.detailData.summary, // 分享描述
          link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: '', // 分享图标
          success: function (res) {
            // 用户点击了分享后执行的回调函数
            let params = {
                employeeId: that.employeeId,
                unionId: that.unionid,
                type: '5',
                targetId: that.id
              }
              shareCallback(params)
          },
        };
        let shareFunction = function () {
          //需在用户可能点击分享按钮前就先调用
            wx.onMenuShareTimeline(that.shareOption);//分享到朋友圈
            wx.onMenuShareAppMessage(that.shareOption);//分享到朋友
            wx.onMenuShareQQ(that.shareOption);//分享到qq
            wx.onMenuShareQZone(that.shareOption);//分享到QQ空间
        };
        //加载微信配置
        wxConfig(shareFunction);
        if (res.data.detail.likesType != 2) {
          this.likesType = res.data.detail.likesType;
        }
        if (res.data.detail.likesType != 1) {
          this.dislikesType = res.data.detail.likesType;
        }
      });
    },
    getSignUrl() {
      var signLink = "";
      var ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        signLink = localStorage.getItem("WxCodeUrl");
        if (!signLink) signLink = location.href;
      } else {
        signLink = location.href;
      }
      return signLink;
    },
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        employeeId: this.employeeId,
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl =
            location.protocol +
            "//" +
            location.host +
            "/auth/vcard?employeeId=" +
            that.employeeId +
            "&target=" +
            encodeURIComponent(
              "/electronicCard/specialTopicDetail" + location.search
            );
          window.location.href = redirectUrl;
          return;
        }
        this.unionid = res.data.unionid;
        this.getDetail();
      });
    },
    skipNewsDetail(id) {
      this.$router.push({
        path: "/electronicCard/caijing/detail",
        query: {
          id,
          employeeId: this.employeeId,
        },
      });
    },
  },
};
</script>
    <style lang="less" scoped>
    /deep/ .van-popup--center{
  background-color: transparent !important;
  top: 20%;
  width: 100%;
  text-align: center;
}
.all {
  height: 100%;
  overflow: auto;
  background-color: #f7f7f7;
}
.top {
  position: absolute;
  z-index: 1;
  width: 100%;

  img {
    width: 100%;
    height: 150px;
  }
}
.detail {
  position: absolute;
  left: 3%;
  z-index: 3;
  width: 94%;
  min-height: 104px;
  background: #ffffff;
  border-radius: 3px;
  overflow: auto;
  margin-top: 120px;
  .title {
    width: 94%;
    height: 42px;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  .abstruct {
    width: 94%;
    min-height: 20px;
    margin: 0 auto;
    font-size: 14px;
    color: #4a4a4a;
    margin-bottom: 10px;
    margin-top: 6px;
  }
}
.list {
  .common {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
    height: 85px;
    background: #ffffff;
    border-radius: 3px;
    .left {
      margin-left: 10px;
      margin-top: 8px;
      width: 68%;
      .leftTop {
        height: 41px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      .leftBot {
      }
    }
    .right {
      margin-right: 10px;
      margin-top: 8px;
      width: 23%;
      img {
        width: 100%;
        height: 57px;
      }
    }
  }
  .sec {
    margin: 0 auto;
    margin-top: 10px;
    width: 94%;
    height: 85px;
    background: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    .secTop {
      width: 94%;
      margin: 0 auto;
      margin-top: 10px;
      height: 41px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 17px;
    }
  }
  .poster {
    width: 94%;
    margin: 0 auto;
    min-height: 389px;
    margin-top: 10px;

    background: #ffffff;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    overflow: hidden;
    .title {
      margin-top: 10px;
      width: 94%;
      margin-left: 10px;
      min-height: 21px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 17px;
    }
    img {
      width: 144px;
      height: 304px;
      margin: 8px;
    }
  }
  .type {
    display: inline-block;
    font-size: 11px;
    color: #333333;
    margin-right: 8px;
  }
  .num {
    font-size: 11px;
    color: #959595;
  }
}
.last {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background: #ffffff;
  .lastContent {
    height: 64px;
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      margin-right: 6px;
      width: 21px;
      height: 21px;
    }
    span {
      font-size: 11px;
      color: #c1c9d0;
    }
    .btn {
      text-align: center;
      line-height: 42px;
      width: 206px;
      height: 42px;
      background: #3974c6;
      border-radius: 5px;
      font-size: 14px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.img1 {
  width: 314px;
  height: 141px;
}
.img2 {
  width: 158px;
  height: 65px;
  margin-top: 30px;
}
.rightposter {
  img {
    width: 144px;
    height: 304px;
  }
  .userInfo{
    display: flex;
    justify-content: space-between;
    width: 144px;
    height: 100px;
    .left{
      display: flex;
      width:80%;
      .avatar{
        width: 30%;
        height: 40px;
      }
      .info{
        margin-left: 4px;
        margin-top: 10px;
        .name{
          font-size: 10px;
        }
        .m12{
          font-size: 8px;
        }
      }
    }
    .right{
      width: 20%;
      .qrcode{
        margin-top: 12px;
        width: 90%;
        height: 30px;
      }
    }
  }
}
</style>